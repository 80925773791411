import PropTypes from 'prop-types';
// FOR REFERENCE: https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/


const Can = ({ permissions, perform, readOnly = false, yes = () => null, no = () => null }) => {
  const $permissions = readOnly
    ? permissions.filter((d) => d.includes('read'))
    : permissions;

  // If Permissions is an array
  if (perform instanceof Array) {
    for (let i = 0; i < perform.length; i++) {
      if ($permissions.includes(perform[i])) {
        return yes();
      }
    }
    return no();
  }

  // If Permissions is a string
  if (typeof perform === 'string') {
    if ($permissions.includes(perform)) {
      return yes();
    }
    return no();
  }
};

Can.propTypes = {
  permissions: PropTypes.array,
  perform: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
  readOnly: PropTypes.bool,
  yes: PropTypes.func,
  no: PropTypes.func,
};

export default Can;
