import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';


const PublicRoute = ({ redirectPath = '/dashboard', children }) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  if (isLoggedIn) return <Navigate to={redirectPath} replace />;

  return children;
};


PublicRoute.propTypes = {
  redirectPath: PropTypes.string,
  children: PropTypes.element,
};

export default PublicRoute;
