import { createApi } from '@reduxjs/toolkit/query/react';

import axiosInstance from '../utils/axios';

// Define base query using our axios instance
export const axiosBaseQuery = ({ baseUrl } = { baseUrl: '' }) => async ({ url, method, data, params, headers, responseType }) => {
  try {
    const response = await axiosInstance({
      url: baseUrl + url,
      method,
      data,
      params,
      headers,
      responseType,
    });

    return {
      data: response.data,
      meta: {
        headers: response.headers,
        status: response.status,
      },
    };
  } catch (axiosError) {
    const err = axiosError;
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    };
  }
};

// Define base api slice. Endpoints are injected from their respective files.
// baseQuery should not actually be utilized since we're using queryFn, but defining it with axios here will make sure that if it is ever used it will go through our custom axios instance
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery({ baseUrl: `${baseURL}/` }),
  endpoints: () => ({}),
});
