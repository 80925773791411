import { createSlice } from '@reduxjs/toolkit';


const initialState = {};

const slice = createSlice({
  name: 'invalidations',
  initialState,
  reducers: {
    updateInvalidations: (invalidations, action) => {
      const newInvalidations = action.payload.reduce((acc, name) => {
        acc[name] = Date.now();
        return acc;
      }, {});

      return {
        ...invalidations,
        ...newInvalidations,
      };
    },
  },
});

export const {
  updateInvalidations,
} = slice.actions;

export default slice;
