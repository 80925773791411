import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'murder-hornet-ui';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useLogoutMutation } from '../../redux/reducers/auth';
import SideNavSection from './SideNavSection';

const SideNav = ({ isOpen, toggle }) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const navigate = useNavigate();

  const [logout] = useLogoutMutation();

  const location = useLocation();

  const [, primaryPath] = location.pathname.split('/');

  return (
    <nav className={`sidenav border-end ${isOpen ? 'active' : ''}`}>
      <div className='d-flex justify-content-between p-3'>
        {/* <div className='sidenav-logo d-flex justify-content-start justify-content-sm-center'>
          {organizationBranding.logo_url && (
            <img
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault();
                  navigate('/');
                }
              }}
              className='img-fluid pointer'
              src={organizationBranding.logo_url} title='organization logo'
              onClick={() => {
                navigate('/')
                if (window.innerWidth <= 576) toggle();
              }}
            />
          )}
        </div> */}
        <p className='fs-5 fw-bold mb-0 px-3 pb-3'>C.S. Portal</p>

        {/* Mobile SideNav close button */}
        <div className='d-sm-none'>
          <Button ghost onClick={toggle}>
            <span className='fas fa-times' />
          </Button>
        </div>
      </div>



      {isLoggedIn && (
        <>
          <Button
            ghost
            block
            className={`bg-light-hover pointer ps-3 pe-3 py-2 mb-3 rounded-0 text-reset fw-normal text-start ${primaryPath === 'dashboard' ? 'bg-primary-subtle fw-semibold' : ''}`}
            onClick={(e) => {
              navigate('/dashboard');
              if (e.currentTarget === document.activeElement) {
                e.currentTarget.blur();
              }
              if (window.innerWidth <= 576) toggle();
            }}
          >
            Dashboard
          </Button>

          <SideNavSection
            title='Organizations'
            perform={['cs:organizations:read-all', 'cs:organizations:read-assigned']}
            toggle={toggle}
            items={[
              {
                id: 1,
                title: 'All Organizations',
                onClick: () => navigate('/organizations'),
                isActive: primaryPath === 'organizations',
                perform: ['cs:organizations:read-all', 'cs:organizations:read-assigned'],
              },
            ]}
          />

          <SideNavSection
            title='Help Sections'
            perform={['cs:help-tickets:read', 'cs:errors:read', 'cs:faq:read', 'cs:release-notes:read', 'cs:tos:read']}
            toggle={toggle}
            items={[
              {
                id: 1,
                title: 'Help Tickets',
                // onClick: () => window.location.href = 'https://www.atlassian.net', // TODO: Jira url
                onClick: () => window.open('https://www.atlassian.net', '_blank'), // TODO: Jira url
                isActive: primaryPath === 'help-tickets',
                perform: 'cs:help-tickets:read',
              },
              {
                id: 2,
                title: 'Error Logs',
                onClick: () => navigate('/error-logs'),
                isActive: primaryPath === 'error-logs',
                perform: 'cs:errors:read',
              },
              {
                id: 3,
                title: 'FAQs',
                onClick: () => navigate('/faqs'),
                isActive: primaryPath === 'faqs',
                perform: 'cs:faq:read',
              },
              {
                id: 4,
                title: 'Release Notes',
                onClick: () => navigate('/release-notes'),
                isActive: primaryPath === 'release-notes',
                perform: 'cs:release-notes:read',
              },
              {
                id: 5,
                title: 'Terms of Service',
                onClick: () => navigate('/tos'),
                isActive: primaryPath === 'tos',
                perform: 'cs:tos:read',
              },
            ]}
          />

          <SideNavSection
            title='Settings'
            toggle={toggle}
            items={[
              {
                id: 1,
                title: 'My Settings',
                onClick: () => navigate('/my-settings'),
                isActive: primaryPath === 'my-settings',
              },
              {
                id: 2,
                title: 'Users',
                onClick: () => navigate('/users'),
                isActive: primaryPath === 'users',
                perform: 'cs:cs-user:read',
              },
              {
                id: 9,
                title: 'Logout',
                onClick: logout,
              },
            ]}
          />

        </>
      )}
    </nav>
  );
};

SideNav.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default SideNav;
