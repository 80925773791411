import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'murder-hornet-ui';
import { Collapse } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { useToggle } from '../../hooks';
import Can from '../../utils/Can';


const SideNavSection = ({ title, items = [], perform, toggle, defaultOpen = true }) => {
  const permissions = useSelector((state) => state.auth.currentUser.permissions);

  const [isOpen, toggleOpen] = useToggle(defaultOpen);

  const content = (
    <>
      <div className='border-top bg-light-hover pointer p-3' onClick={toggleOpen}>
        <div className='d-flex justify-content-between align-items-center fs-7 fw-bold text-muted'>
          <span>{title}</span>
          <Button ghost className='text-reset py-0 px-1' ariaLabel={isOpen ? 'Collapse' : 'Expand'} onClick={(e) => {
            if (e.currentTarget === document.activeElement) {
              e.currentTarget.blur();
            }
          }}>
            {isOpen ? <span className='fas fa-minus ' /> : <span className='fas fa-plus' />}
          </Button>
        </div>
      </div>

      <Collapse in={isOpen}>
        <div>
          <div className='mb-3'>
            {items.map((d) => {
              const item = (
                <Button
                  ghost
                  block
                  key={d.id}
                  className={`bg-light-hover pointer text-reset fw-normal text-start p-0 rounded-0 ps-5 pe-3 py-2 ${d.isActive ? 'bg-primary-subtle fw-semibold' : ''}`}
                  onClick={(e) => {
                    d.onClick();
                    if (e.currentTarget === document.activeElement) {
                      e.currentTarget.blur();
                    }
                    if (window.innerWidth <= 576) toggle();
                  }}
                >
                  {d.title}
                </Button>
              );

              return d.perform
                ? (
                  <Can
                    key={d.id}
                    permissions={permissions}
                    perform={d.perform}
                    yes={() => item}
                  />
                )
                : item;
            })}
          </div>
        </div>
      </Collapse>
    </>
  );

  if (!perform) return content;

  return (
    <Can
      permissions={permissions}
      perform={perform}
      yes={() => content}
    />
  );
};

SideNavSection.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  perform: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
  toggle: PropTypes.func,
  defaultOpen: PropTypes.bool,
};

export default SideNavSection;
