import { configureStore, combineSlices } from '@reduxjs/toolkit';

import { apiSlice } from './apiSlice';
import authSlice, { logout } from './reducers/auth';
import invalidationsSlice from './reducers/invalidations';


export default () => {
  const appReducer = combineSlices(apiSlice, authSlice, invalidationsSlice);

  const rootReducer = (state, action) => {
    if (action.type === logout.type) return appReducer(undefined, action); // reset to initial state on logout
    return appReducer(state, action);
  };

  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: environment !== 'production',
  });
};
