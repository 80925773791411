import React from 'react';
import { createRoot } from 'react-dom/client';

// import { ScrollToTopButton } from 'bumblebee-ui';
import ls from 'local-storage';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { MultiBackend, TouchTransition, MouseTransition } from 'react-dnd-multi-backend';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { login } from './redux/reducers/auth';
import createStore from './redux/store';
import AppRouter from './routers/AppRouter';
import './styles/styles.scss';

export const store = createStore();

const user = ls('user');
if (user) {
  console.log('User: ', user);
  store.dispatch(login(user));
}

const HTML5toTouch = {
  backends: [
    {
      id: 'html5',
      backend: HTML5Backend,
      preview: true,
      transition: MouseTransition,
    },
    {
      id: 'touch',
      backend: TouchBackend,
      options: { enableMouseEvents: true },
      transition: TouchTransition,
    },
  ],
};

const jsx = (
  <Provider store={store}>
    <DndProvider backend={MultiBackend} options={HTML5toTouch}>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover />

      <AppRouter />
      {/* <ScrollToTopButton handleClick={() => {
      window.scrollTo(0, 0);
    }} /> */}
    </DndProvider>
  </Provider>
);

const container = document.getElementById('app');
const root = createRoot(container);
root.render(jsx);
